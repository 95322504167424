import "core-js/modules/es.array.push.js";
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import TableXsr from "@/components/Table/Table-User";
import { onMounted, ref } from "vue";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_User from "@/apis/Apis_User";
import Common_Down from "@/utils/Common/Common_Down";
import Common_Base64 from "@/utils/Common/Common_Base64";
export default {
  __name: 'BatchRegUserLog',
  setup(__props) {
    const LogArr = ref([]);
    const GetLog = () => {
      Apis_User.getLog().then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        LogArr.value.length = 0;
        let arr = res.data || [];
        for (let arrKey in arr) {
          let temp = arr[arrKey];
          LogArr.value.push({
            id: -2,
            username: temp.replace(/.txt/g, '')
          });
        }
      });
    };
    onMounted(() => GetLog());
    const TableEvent = options => {
      console.log('TableEvent', options);
      // [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项
      switch (options.type) {
        case 3:
          VantMsgLib.confirmSubmit('下载日志', () => DownLoadLog(options.data.username));
          break;
        default:
          VantMsgLib.notify(4, '暂不支持');
          break;
      }
    };
    const DownLoadLog = file => {
      Apis_User.getFile(file).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        let str = res.data || '';
        str = Common_Base64.de(str);
        Common_Down.downLoad(str, file + '.txt');
        VantMsgLib.alertSuccess();
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(TableXsr), {
        ViewData: LogArr.value,
        HeaderTitle: "注册日志",
        onTableEvent: TableEvent
      }, null, 8, ["ViewData"]);
    };
  }
};